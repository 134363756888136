import React, { useContext, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import appConfig from 'app.config'
import { IApplicationContext } from 'interfaces/i-application-context-props'
import { ApplicationDispatchContext } from 'contexts/ApplicationContext'
import { MSP_ACTIONS, MspDispatchContext } from 'components/multistep/Multistep'
import updateApplicationServices from 'services/updateApplicationServices'
import CircleSpinner from 'components/circle-spinner/CircleSpinner'
import ErrorBox from 'components/message-boxes/error-box/ErrorBox'

import './Ppi.scss'
import { ReadMoreExpander } from 'components/read-more-expander/ReadMoreExpander'
import { checkIsMobile, checkIsTablet } from 'utils/commonhelpers'

export const PpiDk = () => {
	const { t } = useTranslation()
	const { loanApplication }: IApplicationContext = useContext(
		ApplicationDispatchContext
	)
	const { msp, dispatchMsp } = useContext(MspDispatchContext)
	const [ppiStatus, setPpiStatus] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(false)
	const [error, setError] = useState<boolean>(false)

	const updatePpiConsent = async () => {
		setLoading(true)
		setError(false)
		try {
			await updateApplicationServices
				.updatePpi(
					{
						ppi: ppiStatus,
						applicationId:
							loanApplication.applicationData?.loanNumber!,
					},
					t
				)
				.then((response) => {
					if (response === 200) {
						dispatchMsp({
							type: MSP_ACTIONS.SET_INDEX,
							payload: {
								index: msp.index + 1,
							},
						})
					}
				})
		} catch (err) {
			console.log(err)
			setError(true)
		} finally {
			setLoading(false)
		}
	}

	return (
		<div className="payment-protection-insurance">
			{loading ? (
				<CircleSpinner />
			) : (
				<div className="ppi-section">
					<div>
						<p className="ppi-description">{t('PpiDesc')}</p>
						<ReadMoreExpander
							expanded={checkIsMobile() || checkIsTablet()}
							showExpander={!(checkIsMobile() || checkIsTablet())}
							contentTop={true}
							collapsedHeight={'22rem'}
							children={
								<div>
									<label className="checkbox-container mt-2">
										<input
											type="checkbox"
											name="allowMarketing"
											onChange={() =>
												setPpiStatus(!ppiStatus)
											}
											checked={ppiStatus}
										/>
										<span className="checkmark"></span>
										<p>
											<b>{t('PpiLabel')}</b>
										</p>
									</label>
									<div className="ppi-terms">
										<div className="ppi-information-section">
											<Trans t={t} i18nKey="PpiInfo">
												<p>
													A payment insurance provides
													you with financial security
													in case an unforeseen event
													should affect you.
												</p>
												<p>
													Based on the information you
													provided in the application,
													TF Bank has conducted a
													usage and needs assessment
													that shows you would benefit
													from this insurance. Read
													more about the requirements
													and needs assessment here:{' '}
													<a
														href={
															'http://tfbank.dk/media/5d1npu45/danmark_krav-og-behovsvurdering_05_12_24final.pdf'
														}
														className="link-darkgray"
														target="_blank"
														rel="noopener noreferrer"></a>
													.
												</p>
												<p>
													Below is more information
													about the insurance.
												</p>
											</Trans>
										</div>
										<br />
										<div>{t('PpiLine1')}</div>
										<div>ELLER</div>
										<div>{t('PpiLine2')}</div>
									</div>
								</div>
							}
						/>
						<div className="ppi-link-section">
							<a
								href={
									'https://tfbank.dk/media/ptmpl2gc/36421-tf-bank-lanesikring-forsikringsbetingelser-2_0.pdf'
								}
								className="link-darkgray"
								target="_blank"
								rel="noopener noreferrer">
								TF Bank Lånesikring, forsikringsbetingelser
							</a>
							<a
								href={
									'https://tfbank.dk/media/2jjdqpus/36xxx_lpi_tf-bank_information-om-forsikringsformidling_1_0.pdf'
								}
								className="link-darkgray"
								target="_blank"
								rel="noopener noreferrer">
								Information om forsikringsformidling
							</a>
							<a
								href={
									'https://tfbank.dk/media/r3qojvmd/ipid-lanesikring-18-64-ar-produktinformation-2_0.pdf'
								}
								className="link-darkgray"
								target="_blank"
								rel="noopener noreferrer">
								IPID Lånesikring 18-64 år Produktinformation
							</a>
							<a
								href={
									'https://tfbank.dk/media/cgwcm4h2/ipid-lanesikring-65-75-ar-produktinformation-2_0.pdf'
								}
								className="link-darkgray"
								target="_blank"
								rel="noopener noreferrer">
								IPID Lånesikring 65-75 år Produktinformation
							</a>
						</div>
					</div>
					{error ? (
						<div className="error-box-container">
							<ErrorBox message={t('PpiError')} />
						</div>
					) : null}
				</div>
			)}

			<div className="ppi-next-button">
				<button
					className="btn btn-primary"
					onClick={() => updatePpiConsent()}>
					{t('Next')}
				</button>
			</div>
		</div>
	)
}
